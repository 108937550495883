import PRODUCT_REDIRECT_MAP from './PRODUCT_REDIRECT_MAP';
import COLLECTIONS from './COLLECTIONS';
import AREAS from './AREAS';
import CITIES from './CITIES';
import RECEIPT_TYPES from './RECEIPT_TYPES';
import RECEIPT_CARRIER_TYPES from './RECEIPT_CARRIER_TYPES';
import SHIPMENT_COMPANY from './SHIPMENT_COMPANY';
import PROCESS_STATUS from './PROCESS_STATUS';
import GENDER from './GENDER';
import SUBSCRIBE from './SUBSCRIBE';
import TAPPAY_CARD_TYPE from './TAPPAY_CARD_TYPE';
import PRODUCT_TYPE from './PRODUCT_TYPE';
import PRODUCT_TYPE_2 from './PRODUCT_TYPE_2';
import PRODUCT_TYPE_3 from './PRODUCT_TYPE_3';
import ARTICLE_TYPE from './ARTICLE_TYPE';
import NEWS_TYPE from './NEWS_TYPE';
import PRODUCT_BRAND_TYPE from './PRODUCT_BRAND_TYPE';
import SHOP_AREA from './SHOP_AREA';
import SHOP_STORE from './SHOP_STORE';
import PAYMENT_TYPE from './PAYMENT_TYPE';
import PURCHASE_PURPOSE from './PURCHASE_PURPOSE';
import REFUND_REASON from './REFUND_REASON';
import WARRANTY_TYPE from './WARRANTY_TYPE';

import { TAPPAY_TYPE, TAPPAY_FIELDS_STATUS_CODE } from './TAPPAY';
import { findBy } from '../helper';

const ASSETS_BASE_URL = process.env.ASSETS_BASE_URL;
const API_ORIGIN = process.env.API_ORIGIN;
const GRAPHQL_ENDPOINT = process.env.GRAPHQL_ENDPOINT;
const CANONICAL_ORIGIN = process.env.CANONICAL_ORIGIN;

/** 預設的發票類型 */
const DEFAULT_RECEIPT_TYPE = findBy(RECEIPT_TYPES, 'identifier', 'EINVOICE');

/** 發票類型 - 歸戶至發票載具 */
const RECEIPT_TYPE_CARRIER = findBy(RECEIPT_TYPES, 'identifier', 'CARRIER');

/** 發票類型 - 三聯式發票 */
const RECEIPT_TYPE_TRIPLICATE = findBy(
  RECEIPT_TYPES,
  'identifier',
  'TRIPLICATE'
);

/** 預設的發票載具類型 */
const DEFAULT_RECEIPT_CARRIER_TYPE = findBy(
  RECEIPT_CARRIER_TYPES,
  'identifier',
  'MOBILE'
);

/** 免運費門檻 */
const FREE_SHIPPING_AMOUNT = 1500;
const SHIPPING_FEE = 100;

/** 備註文字跟備註選項的分隔符號 */
const MEMO_SEPARATOR = '|||';

// 2020 1111
// const now = new Date().getTime();
// const periodFrom1111 = new Date('2020-11-05T11:30:00+08:00');
// const periodTo1111 = new Date('2020-11-12T02:00:00+08:00');
// const isPeriod1111 = periodFrom1111 <= now && periodTo1111 >= now;
const isPeriod1111 = false;

const CONSTANTS = {
  ASSETS_BASE_URL,
  API_ORIGIN,
  GRAPHQL_ENDPOINT,
  CANONICAL_ORIGIN,
  PRODUCT_REDIRECT_MAP,
  COLLECTIONS,
  AREAS,
  CITIES,
  RECEIPT_TYPES,
  DEFAULT_RECEIPT_TYPE,
  RECEIPT_TYPE_CARRIER,
  RECEIPT_TYPE_TRIPLICATE,
  RECEIPT_CARRIER_TYPES,
  DEFAULT_RECEIPT_CARRIER_TYPE,
  SHIPMENT_COMPANY,
  PROCESS_STATUS,
  FREE_SHIPPING_AMOUNT,
  MEMO_SEPARATOR,
  TAPPAY_TYPE,
  TAPPAY_FIELDS_STATUS_CODE,
  GENDER,
  SUBSCRIBE,
  TAPPAY_CARD_TYPE,
  PRODUCT_TYPE,
  PRODUCT_TYPE_2,
  PRODUCT_TYPE_3,
  ARTICLE_TYPE,
  NEWS_TYPE,
  PRODUCT_BRAND_TYPE,
  SHOP_AREA,
  SHOP_STORE,
  isPeriod1111,
  PAYMENT_TYPE,
  PURCHASE_PURPOSE,
  SHIPPING_FEE,
  REFUND_REASON,
  WARRANTY_TYPE,
};

// Object.freeze(CONSTANTS);

export default CONSTANTS;

export {
  ASSETS_BASE_URL,
  API_ORIGIN,
  GRAPHQL_ENDPOINT,
  CANONICAL_ORIGIN,
  PRODUCT_REDIRECT_MAP,
  COLLECTIONS,
  AREAS,
  CITIES,
  RECEIPT_TYPES,
  DEFAULT_RECEIPT_TYPE,
  RECEIPT_TYPE_CARRIER,
  RECEIPT_TYPE_TRIPLICATE,
  RECEIPT_CARRIER_TYPES,
  DEFAULT_RECEIPT_CARRIER_TYPE,
  SHIPMENT_COMPANY,
  PROCESS_STATUS,
  FREE_SHIPPING_AMOUNT,
  MEMO_SEPARATOR,
  TAPPAY_TYPE,
  TAPPAY_FIELDS_STATUS_CODE,
  GENDER,
  SUBSCRIBE,
  TAPPAY_CARD_TYPE,
  PRODUCT_TYPE,
  PRODUCT_TYPE_2,
  PRODUCT_TYPE_3,
  ARTICLE_TYPE,
  NEWS_TYPE,
  PRODUCT_BRAND_TYPE,
  SHOP_AREA,
  SHOP_STORE,
  PAYMENT_TYPE,
  PURCHASE_PURPOSE,
  SHIPPING_FEE,
  REFUND_REASON,
  WARRANTY_TYPE,
};
