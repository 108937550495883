// import { findBy, findIndexBy } from '../helper';
import { findBy } from '../helper';
import {
  FREE_SHIPPING_AMOUNT,
  MEMO_SEPARATOR,
  PRODUCT_TYPE,
} from '../constants/_index';

import axios from 'axios';

/**
 * @returns {{ items: { id: string, quantity: number, memo: string }[], couponCode: string, calculateResult: ?{} }}
 */
export function state() {
  return {
    items: [],
    plusItems: [],
    couponCode: '',
    calculateResult: {},
    // isContainFrozenDelivery: false,
  };
}

/*
..######...########.########.########.########.########...######.
.##....##..##..........##.......##....##.......##.....##.##....##
.##........##..........##.......##....##.......##.....##.##......
.##...####.######......##.......##....######...########...######.
.##....##..##..........##.......##....##.......##...##.........##
.##....##..##..........##.......##....##.......##....##..##....##
..######...########....##.......##....########.##.....##..######.
*/
export const getters = {
  // 購物車裡的商品
  // items(state) {
  //   return state.items;
  // },

  /** 是否在購物車裡 */
  // isInCart: state => id => {
  //   const item = findBy(state.items, 'id', id);
  //   return item != null;
  // },

  /** 還差多少免運費 */
  freeShippingAmountDifference(state) {
    return state.calculateResult
      ? state.calculateResult.freeShippingAmountDifference
      : FREE_SHIPPING_AMOUNT;
  },

  /** 是否免運費 */
  // isFreeShipping(state, getters) {
  //   return getters.freeShippingAmountDifference <= 0;
  // },

  /** 取得購物車項目數量 */
  // getItemQuantity: state => id => {
  //   const item = findBy(state.items, 'id', id);
  //   return item && item.quantity;
  // },

  /** 取得贈送商品數量 */
  getGiveProductQuantity: state => id => {
    try {
      const item = findBy(
        // state.calculateResult.coupon.giveawayProducts,
        state.calculateResult.giveawayProducts2,
        'productID',
        id
      );
      return item && item.quantity;
    } catch (e) {
      return 0;
    }
  },

  /** 取得購物車項目備註 */
  // getItemMemo: state => id => {
  //   const item = findBy(state.items, 'id', id);
  //   return item && item.memo;
  // },

  /** 解出購物車項目的備註文字 */
  // getItemMemoText: (state, getters) => id => {
  //   const memo = getters.getItemMemo(id);
  //   return memo ? memo.split(MEMO_SEPARATOR)[0] : '';
  // },

  /** 解出購物車項目的備註選項 */
  // getItemMemoOption: (state, getters) => id => {
  //   const memo = getters.getItemMemo(id);
  //   return memo ? memo.split(MEMO_SEPARATOR)[1] : '';
  // },

  /** 購物車項目是否庫存不足 */
  // isItemStockNotAmple: state => id =>
  //   Boolean(
  //     state.calculateResult &&
  //       state.calculateResult.stockNotAmpleIDs.includes(id)
  //   ),

  /** 排除撈不到的商品後，購物車是否還有東西 */
  // isNotEmpty(state) {
  //   return Boolean(
  //     state.items.length >= 1 &&
  //       state.calculateResult &&
  //       state.calculateResult.unavailableItemIDs.length !== state.items.length
  //   );
  // },

  /** 優惠券代碼是否OK */
  isCouponValid(state) {
    return (
      !state.couponCode ||
      (state.calculateResult && state.calculateResult.isCouponCouldUse)
    );
  },

  /** 輸入優惠券代碼的回饋訊息 */
  couponValidationMessage({ couponCode, calculateResult }) {
    if (couponCode && calculateResult) {
      if (calculateResult.isCouponCouldUse) {
        return `優惠券「${calculateResult.coupon.name}」`;
      } else {
        if (calculateResult.isCouponFound) {
          if (calculateResult.isCouponUsed) {
            return `已使用過優惠券「${calculateResult.coupon.name}」`;
          } else if (!calculateResult.isCouponActive) {
            return `優惠券「${calculateResult.coupon.name}」已過期`;
          } else if (calculateResult.isCouponReachLimit) {
            return `優惠券「${calculateResult.coupon.name}」已超過使用上限`;
          } else if (calculateResult.isCouponGiveawayStockNotEnough) {
            return `優惠券「${calculateResult.coupon.name}」 贈品庫存不足`;
          } else {
            return `不適用優惠券「${calculateResult.coupon.name}」`;
          }
        } else {
          return '無效的優惠券代碼';
        }
      }
    } else {
      return '';
    }
  },

  /** 數量總計 */
  totalQuantity(state) {
    return state.items.reduce((sum, item) => sum + item.quantity, 0);
  },
};

/*
.##.....##.##.....##.########....###....########.####..#######..##....##..######.
.###...###.##.....##....##......##.##......##.....##..##.....##.###...##.##....##
.####.####.##.....##....##.....##...##.....##.....##..##.....##.####..##.##......
.##.###.##.##.....##....##....##.....##....##.....##..##.....##.##.##.##..######.
.##.....##.##.....##....##....#########....##.....##..##.....##.##..####.......##
.##.....##.##.....##....##....##.....##....##.....##..##.....##.##...###.##....##
.##.....##..#######.....##....##.....##....##....####..#######..##....##..######.
*/
export const mutations = {
  addItem(
    state,
    {
      id,
      quantity = 1,
      price = 0,
      memo = '',
      memoText = '',
      memoOption = '',
      isFrozenDelivery = false,
      product = null,
    }
  ) {
    if (Number.isInteger(id)) {
      id = id + '';
    }

    const item = findBy(state.items, 'id', id);
    if (!item) {
      this.app.$fbq('track', 'AddToCart', {
        content_type: 'product',
        content_ids: id,
        value: price,
        currency: 'TWD',
      });

      this.app.$gtag('event', 'add_to_cart', { items: [{ id }] });
      let datalayerProduct = null;

      if (product) {
        let category = '--';
        try {
          const temp = PRODUCT_TYPE.find(z => z.id == product.newTypeIDs[0]);
          if (temp) {
            category = temp.name;
          }
        } catch (e) {
          // yada
        }

        let brandName = '--';
        try {
          brandName = product.brand.name;
        } catch (e) {
          // yada
        }

        datalayerProduct = {
          id: product.id,
          // name: product.nameFull,
          name: product.name,
          category: category,
          price: product.price,
          brand: brandName,
          variant: '',
          quantity: quantity,
        };

        // Add To Cart
        window.dataLayer.push({
          addToCart: {
            products: [datalayerProduct],
          },
          event: 'td_AddToCart',
        });
      }

      state.items.push({
        id,
        quantity,
        memo:
          memoText || memoOption
            ? `${memoText}${MEMO_SEPARATOR}${memoOption}`
            : memo,
        isFrozenDelivery,
        datalayerProduct,
      });

      // set localStorae
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (!temp) {
          items.push({
            id,
            quantity,
          });
          localStorage.setItem('cartItems', JSON.stringify(items));
        }
      } catch (e) {
        // yada
      }

      // add tracing log
      try {
        let tracingID = localStorage.getItem('tracingID');

        const postData = {
          _model: 'addToCart',
          productID: Number.parseInt(id),
        };
        if (tracingID) {
          postData.tracingID = Number.parseInt(tracingID);
        }

        axios.post(`${process.env.API_ORIGIN}/update/update`, postData);
      } catch (e) {
        // yada
      }
    } else {
      // exists in cart
      item.quantity = quantity;
      item.memo =
        memoText || memoOption
          ? `${memoText}${MEMO_SEPARATOR}${memoOption}`
          : memo;
    }
  },

  addPlusItem(state, { id, quantity = 1, product = null }) {
    const item = findBy(state.plusItems, 'id', id);
    if (!item) {
      this.app.$fbq('track', 'AddToCart', {
        content_type: 'product',
        content_ids: id,
        value: product.price,
        currency: 'TWD',
      });

      this.app.$gtag('event', 'add_to_cart', { items: [{ id }] });
      let datalayerProduct = null;

      console.log('product');
      console.log(product);
      if (product) {
        let category = '--';
        try {
          const temp = PRODUCT_TYPE.find(z => z.id == product.newTypeIDs[0]);
          if (temp) {
            category = temp.name;
          }
        } catch (e) {
          // yada
        }

        let brandName = '--';
        try {
          brandName = product.brand.name;
        } catch (e) {
          // yada
        }

        datalayerProduct = {
          id: product.id,
          // name: product.nameFull,
          name: product.name,
          category: category,
          price: product.price,
          brand: brandName,
          variant: '',
          quantity: quantity,
        };

        // Add To Cart
        window.dataLayer.push({
          addToCart: {
            products: [datalayerProduct],
          },
          event: 'td_AddToCart',
        });
      }

      state.plusItems.push({
        id,
        quantity,
        // datalayerProduct,
      });

      // set localStorage
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (!temp) {
          plusItems.push({
            id,
            quantity,
          });
          localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
        }
      } catch (e) {
        // yada
      }
    } else {
      // exists in cart
      item.quantity = quantity;
    }
  },

  removeItem(state, { id }) {
    // const itemIndex = findIndexBy(state.items, 'id', id);

    const itemIndex = state.items.findIndex(z => z.id == id);

    console.log('removeItem', id);
    if (itemIndex !== -1) {
      this.app.$gtag('event', 'remove_from_cart', {
        items: [{ id }],
      });

      try {
        if (state.items[itemIndex].datalayerProduct) {
          var product = state.items[itemIndex].datalayerProduct;

          // Remove From Cart
          window.dataLayer.push({
            remove: {
              products: [product],
            },
            event: 'td_removeFromCart',
          });
        }
      } catch (e) {
        // yada
      }
      state.items.splice(itemIndex, 1);

      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const index = items.findIndex(z => z.id == id);
        items.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {
        // yada
      }

      // check is clean plusItems
      console.log(state.items);
      if (state.items.filter(z => z.id == 3606 || z.id == 3605).length <= 0) {
        console.log('clean plus in cart');
        state.plusItems = [];
        localStorage.setItem('cartPlusItems', JSON.stringify([]));
      }
    }
  },

  removePlusItem(state, { id }) {
    // const itemIndex = findIndexBy(state.items, 'id', id);

    const itemIndex = state.plusItems.findIndex(z => z.id == id);

    if (itemIndex !== -1) {
      this.app.$gtag('event', 'remove_from_cart', {
        items: [{ id }],
      });

      try {
        if (state.plusItems[itemIndex].datalayerProduct) {
          var product = state.plusItems[itemIndex].datalayerProduct;

          // Remove From Cart
          window.dataLayer.push({
            remove: {
              products: [product],
            },
            event: 'td_removeFromCart',
          });
        }
      } catch (e) {
        // yada
      }
      state.plusItems.splice(itemIndex, 1);

      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const index = plusItems.findIndex(z => z.id == id);
        plusItems.splice(index, 1);
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {
        // yada
      }
    }
  },

  cleanPlusItems(state) {
    // const itemIndex = findIndexBy(state.items, 'id', id);

    state.plusItems = [];
    localStorage.setItem('cartPlusItems', JSON.stringify([]));
  },

  increaseItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.quantity += amount;
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {
        // yada
      }
    }
  },

  decreaseItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.items, 'id', id);
    if (item && item.quantity - amount >= 1) {
      item.quantity -= amount;
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {
        // yada
      }
    }
  },

  /*
    .########..##.......##.....##..######.
    .##.....##.##.......##.....##.##....##
    .##.....##.##.......##.....##.##......
    .########..##.......##.....##..######.
    .##........##.......##.....##.......##
    .##........##.......##.....##.##....##
    .##........########..#######...######.
    */

  increasePlusItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.plusItems, 'id', id);
    if (item) {
      item.quantity += amount;
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {
        // yada
      }
    }
  },

  decreasePlusItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.plusItems, 'id', id);
    if (item && item.quantity - amount >= 1) {
      item.quantity -= amount;
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {
        // yada
      }
    }
  },

  setItemMemo(state, { id, memo = '' }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.memo = memo;
    }
  },

  setItemMemoText(state, { id, text = '' }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.memo = text + MEMO_SEPARATOR + item.memo.split(MEMO_SEPARATOR)[1];
    }
  },

  setItemMemoOption(state, { id, option = '' }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.memo = item.memo.split(MEMO_SEPARATOR)[0] + MEMO_SEPARATOR + option;
    }
  },

  applyCouponCode(state, { couponCode = '' } = {}) {
    state.couponCode = couponCode;
  },

  setCart(state, { cart: { items = [], couponCode = '' } = {} } = {}) {
    state.items = items;
    state.couponCode = couponCode;
  },

  setCoupon(state, couponCode) {
    state.couponCode = couponCode;
  },

  initCart(state, items) {
    state.items = items;
  },

  initCartPlus(state, items) {
    state.plusItems = items;
  },

  // updateCalculateResult(state, { calculateResult = null } = {}) {
  //   state.calculateResult = calculateResult;

  //   try {
  //     // state.isContainFrozenDelivery = state.items.some(
  //     //   z => z.isFrozenDelivery === true
  //     // );

  //     for (const i in calculateResult.unavailableItemIDs) {
  //       const x = calculateResult.unavailableItemIDs[i];
  //       const itemIndex = findIndexBy(state.items, 'id', x);
  //       if (itemIndex !== -1) {
  //         state.items.splice(itemIndex, 1);
  //       }
  //     }
  //   } catch (e) {
  //     // yada
  //   }
  // },

  clean(state) {
    localStorage.setItem('cartItems', '[]');
    localStorage.setItem('cartPlusItems', '[]');

    state.items = [];
    state.plusItems = [];
  },
};

/*
....###.....######..########.####..#######..##....##..######.
...##.##...##....##....##.....##..##.....##.###...##.##....##
..##...##..##..........##.....##..##.....##.####..##.##......
.##.....##.##..........##.....##..##.....##.##.##.##..######.
.#########.##..........##.....##..##.....##.##..####.......##
.##.....##.##....##....##.....##..##.....##.##...###.##....##
.##.....##..######.....##....####..#######..##....##..######.
*/
export const actions = {
  clean() {
    localStorage.setItem('cartItems', '[]');
    localStorage.setItem('cartPlusItems', '[]');
  },
};
