const items = [
  {
    id: 1,
    name: '品牌直營與合作購物平台',
    memo:
      'Unipapa 官網、品牌直營電商平台 Shopee、Pinkoi、CitiSocial 及合作團購平台',
  },
  { id: 2, name: '私密團購專屬', memo: null },
  { id: 3, name: '企業禮贈與採購', memo: null },
  { id: 4, name: '經銷商及實體店面', memo: null },
];

export default items;
